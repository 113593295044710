require('bootstrap/js/dist/util.js');
require('bootstrap/js/dist/alert.js');
// require('bootstrap/js/src/button.js');
require('bootstrap/js/dist/carousel.js');
require('bootstrap/js/dist/collapse.js');
// require('bootstrap/js/src/dropdown.js');
require('bootstrap/js/dist/modal.js');
require('bootstrap/js/dist/scrollspy.js');
require('bootstrap/js/dist/tab.js');
require('bootstrap/js/dist/tooltip.js');
// require('bootstrap/js/src/popover.js');
