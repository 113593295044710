window.jQuery = window.$ = require('jquery');

require('lazysizes');
require('arrive');
require('./thirdParty/bootstrap');
require('jquery-mask-plugin');

setTimeout(function () {
    $('.lazyload-low-prio').addClass('lazyload').removeClass('lazyload-low-prio');
}, 300);

$(document).arrive('.lazyload-low-prio', function () {
    var $this = $(this);

    setTimeout(function () {
        $this.addClass('lazyload').removeClass('lazyload-low-prio');
    }, 300);
});
